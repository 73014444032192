<template>
  <div class="wrapper">
    <div class="cancel" @click="goBack">
      <CloseIcon />
    </div>

    <div class="auth-banner">
      <img
        :src="
          this.currentLanguage === 'fr'
            ? '/img/banners/login-banner-fr.png'
            : '/img/banners/login-banner-en.png'
        "
        style="width: 100%; border-radius: 10px; max-width: 100%"
      />
    </div>
    <section class="register-form-wrapper p-4" v-show="action_change_password">
      <h5 class="text-center">{{ $t("enterOtpAndPassword") }}</h5>
      <div class="sent-code text-center mb-4">
        <p class="">{{ $t("sentCodeToPhone") }}</p>
        <br />
        <!-- <strong> +{{ formatPhoneNumber(msisdn) }} </strong> -->
      </div>
      <div class="form-wrapper mb-3">
        <form autocomplete="off">
          <div class="mb-3">
            <!-- <input
              type="number"
              class="form-control"
              id="phoneInput"
              :placeholder="$t('phoneNumber071')"
              :aria-label="$t('enterPhoneNumber')"
              name="mobile"
              v-model="msisdn"
              readonly
            /> -->
            <TextInput
              type="number"
              v-model="msisdn"
              :placeholder="$t('phoneNumber')"
              inputmode="numeric"
              readonly
            />
          </div>
          <div
            class="verification-code-wrapper mb-3"
            style="display: flex; justify-content: space-between; gap: 10px"
          >
            <input
              v-for="(value, index) in code"
              :key="index"
              class="otp__input"
              type="number"
              inputmode="numeric"
              maxlength="1"
              autocomplete="off"
              autocorrect="off"
              spellcheck="false"
              :name="generateRandomName(`${code[index]}`)"
              v-model.trim="code[index]"
              @input="handleInput(index, $event)"
              @keydown="blockInvalidChar"
              @keydown.backspace="handleBackspace(index)"
              @focus="handleFocus(index)"
            />
          </div>
          <!-- 
          <div
            v-if="allowResendTimer"
            class="login-button-wrapper text-center p-3 mb-3"
          >
            <div
              class="text-center mb-3 allow_resend_text"
              :style="{ color: resendSecond > 0 ? 'gray' : 'var(--dark-red)' }"
            >
              {{ $t("resendCodein") }} {{ this.resendSecond }}
              {{ $t("seconds") }}
            </div>
          </div>
          <div
            v-if="allowResend && !spinner && !allowResendTimer"
            class="login-button-wrapper text-center p-3 mb-3"
          >
            <div
              class="text-center mb-3 allow_resend_text"
              style="color: var(--dark-red)"
              @click="handleTokenResend"
            >
              <span>{{ $t("resendCodeText") }}</span>
            </div>
          </div> -->

          <div class="mb-3">
            <div class="input-group" style="position: relative">
              <input
                :type="type"
                class="form-control1"
                id="inputPass"
                style="z-index: 2"
                name="password"
                :placeholder="$t('enterYourPassword')"
                aria-label="Password"
                v-model="password"
              />
              <span
                class="btn btn-sm password-toggle"
                style="
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);
                  background: none;
                  border: none;
                  cursor: pointer;
                  z-index: 3;
                "
                @click="showPassword"
                type="button"
              >
                <img :src="btnText" style="width: 16px" />
              </span>
            </div>
          </div>

          <div class="mb-0">
            <div class="input-group" style="position: relative">
              <input
                :type="confirmType"
                class="form-control1"
                id="newInput"
                style="z-index: 2"
                name="confirmPassword"
                :placeholder="$t('confirmPassword')"
                :aria-label="$t('confirmPassword')"
                v-model="confirmPassword"
              />
              <span
                class="btn btn-sm password-toggle"
                style="
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);
                  background: none;
                  border: none;
                  cursor: pointer;
                  z-index: 3;
                "
                @click="showConfirmPassword"
                type="button"
              >
                <img :src="confirmBtnText" style="width: 16px" />
              </span>
            </div>
          </div>
          <div class="disclaimer">
            <template v-if="passwordsMatch">
              {{ $t("atLeast6CharactersContaining") }}
              <strong>{{ $t("aLetter") }}</strong> and
              <strong>{{ $t("aNumber") }}</strong
              >.
            </template>
            <template v-else>
              <p style="color: #dd4646">
                {{ $t("passwordsDoNotMatch") }}
              </p>
            </template>
          </div>
        </form>
      </div>

      <div>
        <p
          v-if="resendLoading"
          style="text-transform: capitalize; text-align: center"
          class="mb-3"
        >
          {{ $t("loading") }}...
        </p>

        <div
          v-if="allowResendTimer"
          class="login-button-wrapper text-center p-3 mb-0"
        >
          <div
            class="text-center mb-3 allow_resend_text"
            :style="{ color: resendSecond > 0 ? 'gray' : 'var(--dark-red)' }"
          >
            {{ $t("resendCodein") }} {{ this.resendSecond }} {{ $t("seconds") }}
          </div>
        </div>
        <div
          v-if="allowResend && !resendLoading && !allowResendTimer"
          class="login-button-wrapper text-center p-3"
        >
          <div
            class="text-center allow_resend_text"
            style="color: var(--dark-red)"
            @click="tokenResend"
          >
            <span>{{ $t("resendCodeText") }}</span>
          </div>
        </div>
      </div>

      <ChopbetButton
        :loading="spinner"
        variant="Variant"
        @click="handleChangePasswordClick"
        :disabled="
          !msisdn ||
          !(password.length > 5) ||
          !fullCode ||
          !passwordsMatch ||
          !confirmPassword
        "
        ><span>{{ $t("changePassword") }}</span>
      </ChopbetButton>
    </section>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import CloseIcon from "../components/CloseIcon.vue";
import TextInput from "../../../components/ui/TextInput.vue";

export default {
  name: "change-password",
  components: { ChopbetButton, CloseIcon, TextInput },
  comments: {},
  data: function () {
    return {
      msisdn: "",
      password: "",
      confirmPassword: "",
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      resendLoading: false,
      action_change_password: true,
      placeBet: 0,
      type: "password",
      allowResend: false,
      allowResendTimer: true,
      resendSecond: 30,
      confirmType: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      confirmBtnText: "/img/icons/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
      code: new Array(4).fill(""),
      fullCode: "",
      countdownInterval: null,
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    passwordsMatch() {
      return this.password === this.confirmPassword || !this.confirmPassword;
    },
    ...mapState(["referralcode"]),
  },
  watch: {
    code: {
      handler() {
        if (this.code.join("").length === 4) {
          this.fullCode = this.code.join("");
          this.handleVerifyClick();
          this.allowResendTimer = true;
          if (this.resendSecond === 0) {
            this.startCountdown();
          }
        }
      },
      deep: true,
      // handler() {
      //   if (this.code.join("").length === 4) {
      //     this.fullCode = this.code.join("");
      //   }
      // },
      // deep: true,
    },
  },
  methods: {
    formatPhoneNumber(msisdn) {
      return msisdn.replace(/^(\d{3})(\d{3})(\d{3})(\d{4})$/, "$1 $2 $3 $4");
    },
    handleLoginClick() {
      this.login();
    },
    startCountdown() {
      this.resendSecond = 30;
      this.allowResend = false;
      this.allowResendTimer = true;

      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      this.countdownInterval = setInterval(() => {
        this.resendSecond--;

        if (this.resendSecond === 0) {
          clearInterval(this.countdownInterval);
          this.allowResend = true;
          this.allowResendTimer = false;
        }
      }, 1000);
    },

    tokenResend: function () {
      this.reset();

      if (this.msisdn === null) {
        this.setError(`${this.$t("invalidPhoneNumber")}`);
        return;
      }

      this.resendLoading = true;
      this.loading = "loading";

      var vm = this;
      var path = `${process.env.VUE_APP_BASE_IDENTITY_URL}/token/resend?lang=${this.$i18n.locale}`;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var payload = {
        phone_number: `225${this.msisdn}`,
      };

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
            lang: this.$i18n.locale || "fr",
          },
        })
        .then((res) => {
          var profile = res.data;

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);
          vm.resendLoading = false;
          vm.loading = "";

          vm.startCountdown();

          vm.$nextTick(() => {
            vm.setSuccess(`${res?.data?.message || this.$t("resentOtp")}`);
          });
        })
        .catch((err) => {
          vm.resendLoading = false;
          vm.loading = "";
          console.log("Error:", err);
          console.log("err fd", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Request:", err.request);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Message:", err.message);
          }
        });
    },

    generateRandomName(baseName) {
      return `${baseName}-${Math.random().toString(36).substring(7)}`;
    },
    trackLoginButtonClick(event) {
      pushToDataLayer("gtm.login", {
        category: "Button",
        action: "Click",
        label: "Login",
        element: event.target,
      });
    },
    handleInput(index, event) {
      const value = event.target.value;

      // Replace non-numeric characters and limit input to 1 character
      this.code[index] = value.replace(/[^0-9]/g, "").slice(0, 1);
      if (event.target.value.length === 1) {
        const nextInput = this.$el.querySelectorAll(".otp__input")[index + 1];
        if (nextInput) nextInput.focus();
      }
    },
    handleBackspace(index) {
      if (this.code[index] === "") {
        const prevInput = this.$el.querySelectorAll(".otp__input")[index - 1];
        if (prevInput) prevInput.focus();
      }
    },
    handleFocus(index) {
      if (this.code[index] === "0") {
        this.code[index] = "";
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.setError(`${this.$t("pleaseEnterValidMobile")}`);
        document.getElementById("phoneInput").classList.add("is-invalid");
      } else {
        document.getElementById("phoneInput").classList.remove("is-invalid");
      }
    },
    blockInvalidChar(e) {
      if (["e", "E", "+", "-", "."].includes(e.key)) {
        e.preventDefault();
      }
    },
    showPassword: function () {
      if (document.getElementById("inputPass").type == "password") {
        document.getElementById("inputPass").type = "text";
      } else {
        document.getElementById("inputPass").type = "password";
      }

      if (this.type === "password") {
        this.type = "text";
        this.btnText = "/img/icons/eye-slash.svg";
      } else {
        this.type = "password";
        this.btnText = "/img/icons/eye.svg";
      }
    },
    showConfirmPassword: function () {
      if (document.getElementById("newInput").type == "password") {
        document.getElementById("newInput").type = "text";
      } else {
        document.getElementById("newInput").type = "password";
      }

      if (this.confirmType === "password") {
        this.confirmType = "text";
        this.confirmBtnText = "/img/icons/eye-slash.svg";
      } else {
        this.confirmType = "password";
        this.confirmBtnText = "/img/icons/eye.svg";
      }
    },

    handleChangePasswordClick(event) {
      this.changePassword(event);
    },
    trackChangePasswordButtonClick(event) {
      pushToDataLayer("gtm.changePassword", {
        category: "Button",
        action: "Click",
        label: "Change Password",
        element: event.target,
      });
    },
    changePassword: function (event) {
      this.reset();

      if (this.password.length < 4) {
        this.setError(`${this.$t("moreThan4Xters")}`);
        return;
      }

      // Combine the four verification code inputs into a single string
      const fullCode = this.code.join("");

      if (fullCode.length !== 4) {
        this.setError(`${this.$t("valid4Digit")}`);
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = `${process.env.VUE_APP_URL_PASSWORD_CHANGE}?lang=${this.$i18n.locale}`;

      var payload = {
        msisdn: `225${this.msisdn}`,
        password: this.password,
        code: parseInt(fullCode),
      };

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(JSON.stringify(res));

          vm.spinner = false;
          vm.loading = "";

          vm.trackChangePasswordButtonClick(event);

          vm.setSuccess(
            `${res?.data?.message || this.$t("passwordChangeSuccessfully")}`
          );

          vm.$router.push("/login");
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },
  },
  mounted: function () {
    this.startCountdown();
    const msisdn = localStorage.getItem("mssdn__reg_xpk_f");
    this.msisdn = msisdn;
    this.$store.dispatch("setCurrentPage", "change-password");
    this.placeBet = this.getValue("placeBet");
    var p = this.getProfile();
    if (!p) {
      console.log("");
    } else {
      this.$router.push({ name: "home", params: {} });
      return;
    }
  },
};
</script>

<style src="./index.css" scoped></style>
